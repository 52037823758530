//other imports
import { Grid } from '@mui/material';
import { BigMenu } from './top/BigMenu';
import { SmallMenu } from './top/SmallMenu';
import InfoIcon from '@mui/icons-material/Info';
import ChairIcon from '@mui/icons-material/Chair';
import WeekendIcon from '@mui/icons-material/Weekend';
import CircleIcon from '@mui/icons-material/Circle';

const menuLinks = [
    {icon: <InfoIcon/>, label: "Info", url: "/"},
    {icon: <ChairIcon/>, label: "(Auto)stoelreiniging", url: "/car"},
    {icon: <WeekendIcon/>, label: "Zetelreiniging", url: "/seat"},
    {icon: <CircleIcon/>, label: "Tapijtreiniging", url: "/carpet"}
]

export function TopNavigation(props){
    return (
        <>
            <Grid container style={{backgroundColor: "#25aae1"}}>
                <Grid item xs={2} style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                    <img src='./../logo_without_text.png' alt='logo' width={50} height={50} style={{margin: 5, padding: 5, borderRadius: 45, backgroundColor: "white", boxShadow: "2px 2px 4px #25aae1"}}/>
                </Grid>
                <Grid item xs={8}>
                    <BigMenu tabSelected={props.tabSelected} menuLinks={menuLinks}/>
                </Grid>
                <Grid item xs={2} style={{display: "flex", justifyContent: "right", alignContent: "center"}}>
                    <SmallMenu menuLinks={menuLinks}/>
                </Grid>
            </Grid>
        </>
    )
}