export function TableBody({title, options}){
    return (
        <>
            <tr>
                <td style={{padding: "5px", borderBottom: "2px solid black"}}><strong>{title}</strong></td>
                <td style={{borderBottom: "2px solid black", backgroundColor: "#25aae1", borderRight: "1px solid white"}}></td>
            </tr>
            {options.map(i=>(
                <>
                    <tr>
                        <td style={{padding: "10px", paddingLeft: "25px", borderBottom: (options[options.length-1] === i?"":"1px solid #ddd")}}>{i.displayName}</td>    
                        <td style={{backgroundColor: "#25aae1", borderBottom: (options[options.length-1] === i?"":"1px solid #ddd"), textAlign: "center", borderRight: (options[options.length-1] === i?"":"1px solid white"), borderRadius: (options[options.length-1] === i?"0 0 20px 20px":"")}}><code>&euro;</code> {i.price}</td>
                    </tr>
                </>
            ))}
        </>
    )
}