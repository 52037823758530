import { Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useState } from "react"
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddIcon from '@mui/icons-material/Add';
import { useAddPhoto } from "../../hooks/photo/useAddPhoto";
import { Delay } from "../delay/Delay";

export function AddResultForm(){
    const [category, setCategory] = useState("");

    const [alt, setAlt] = useState("");
    const [imageBefore, setImageBefore] = useState();
    const [imageAfter, setImageAfter] = useState();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const {mutate} = useAddPhoto({onSuccess, onError});

    function handleSubmit(){
        console.log("submit")
        console.log(imageBefore)
        mutate({alt, imageBefore, imageAfter, category});
    }

    async function onSuccess(){
        setIsSuccess(true);
        await Delay(5000);
        setIsSuccess(false);
    }

    async function onError(err){
        setErrorMessage(err.message)
        setIsError(true);
        await Delay(5000);
        setIsError(false);
    }

    return (
        <>
            <form>
                <TextField
                    required
                    fullWidth
                    label="Beschrijving afbeelding"
                    defaultValue="Koffer"
                    onChange={(e)=>setAlt(e.target.value)}
                    value={alt}
                />
                <FormControl fullWidth style={{marginTop: 10}}>
                    <InputLabel id="demo-simple-select-label">Categorie</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={category}
                        label="Categorie"
                        onChange={(e) => setCategory(e.target.value)}
                        style={{textAlign: 'left'}}
                    >
                        <MenuItem value={'car'}>Auto</MenuItem>
                        <MenuItem value={'carpet'}>Tapijt</MenuItem>
                        <MenuItem value={'seat'}>Zetel</MenuItem>
                    </Select>
                </FormControl>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <input type="file" accept="image/*" id="UploadBefore" style={{display:"none"}} onChange={(event)=>setImageBefore(event.target.files[0])}/>
                        <label htmlFor="UploadBefore">
                            <Button startIcon={<CloudUploadIcon/>} style={{width: "100%", backgroundColor: "white", color: "#25aae1", marginTop: 10}} variant="contained" component="span">Upload voor</Button>
                        </label>
                    </Grid>
                    <Grid item xs={6}>
                        <input type="file" accept="image/*" id="UploadAfter" style={{display:"none"}} onChange={(event)=>setImageAfter(event.target.files[0])}/>
                        <label htmlFor="UploadAfter">
                            <Button startIcon={<CloudUploadIcon/>} style={{width: "100%", backgroundColor: "white", color: "#25aae1", marginTop: 10}} variant="contained" component="span">Upload na</Button>
                        </label>
                    </Grid>
                </Grid>
                <Button startIcon={<AddIcon/>} style={{width: "100%", backgroundColor: "#25aae1", marginTop: 20}} variant="contained" onClick={handleSubmit}>Voeg resultaat toe</Button>
                <span style={{color: "red", display: (isError?"block":"none")}}>{errorMessage}</span>
                <span style={{color: "green", display: (isSuccess?"block":"none")}}>Resultaat toevoegen gelukt!</span>
            </form>
        </>
    )
}