import { GenPrice } from "../../generalComponents/GenPrice"

const options = [
    {
        displayName: '1 stoel',
        price: 25
    },
    {
        displayName: '2 stoel',
        price: 50
    },
    {
        displayName: '3 stoel',
        price: 75
    },
    {
        displayName: '4 stoel',
        price: 100
    },
    {
        displayName: '5 stoel',
        price: 125
    },
    {
        displayName: '6 stoel',
        price: 150
    },
    {
        displayName: '7 stoel',
        price: 175
    },
    {
        displayName: '8 stoel',
        price: 200
    }
]

export function Price(){
    return (
        <>
            <GenPrice options={options}/>
        </>
    )
}