import './App.css';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { firebaseConfig } from './firebaseConfig';
import { initializeApp } from "firebase/app";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./screens/Home"
import { AddResults } from './screens/AddResults';
import { Login } from './screens/Login';
import { Car } from './screens/Car';
import { Seat } from './screens/Seat';
import { Carpet } from './screens/Carpet';

const queryClient = new QueryClient();
const app = initializeApp(firebaseConfig);

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
              {/* Home */}
              <Route index element={<Home/>} path={"/"}/>
              <Route index element={<Home/>} path={"/info"}/>
              <Route index element={<Home/>} path={"/home"}/>

              {/* Car */}
              <Route element={<Car/>} path={"/car"}/>
              <Route element={<Car/>} path={"/carcleaning"}/>
              <Route element={<Car/>} path={"/wagen"}/>
              <Route element={<Car/>} path={"/auto"}/>

              {/* Seat */}
              <Route element={<Seat/>} path={"/seat"}/>
              <Route element={<Seat/>} path={"/chair"}/>
              <Route element={<Seat/>} path={"/zetel"}/>

              {/* Carpet */}
              <Route element={<Carpet/>} path={"/carpet"}/>
              <Route element={<Carpet/>} path={"/rug"}/>
              <Route element={<Carpet/>} path={"/tapijt"}/>

              {/* Add result */}
              <Route element={<AddResults/>} path={"/addResult"}/>

              {/* Login */}
              <Route element={<Login/>} path={"/login"}/>
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </>
  );
}

export default App;
