import { Dialog, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export function PhotosDialog({open, handleClose, pathBefore, pathAfter}){
    return(
        <>
            <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={'xl'}>
                <DialogTitle>
                    Foto volledig scherm
                    <IconButton aria-label="close" style={{float: 'right'}} onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <h2 style={{textAlign: "center"}}>Voor</h2>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <img src={pathBefore} alt="before" style={{maxWidth: "80%"}}/>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                        <h2 style={{textAlign: "center"}}>Na</h2>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <img src={pathAfter} alt="after" style={{maxWidth: "80%"}}/>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}