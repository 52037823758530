import { TopNavigation } from "../components/navigation/TopNavigation";
import { Info } from "../components/home/info/Info";
import { Contact } from "../components/home/contact/Contact";

export function Home(){
    return (
        <>
            <TopNavigation tabSelected={0}/>
            <div style={{marginTop: '-50px'}}></div>
            <Info/>
            <Contact/>
        </>
    )
}