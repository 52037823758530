import { Grid } from "@mui/material";

export function Info(){
    return (
        <div id="info">
            <h2>Info</h2>
            <div style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                <Grid container spacing={2} style={{width: "80vw"}}>
                    <Grid item xs={12} md={4}>
                        <img src="./../steamcleaned_logo_png.png" alt="logo" style={{width: "100%"}}/>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <p style={{textJustify: "inter-word"}}>
                            voor het proffesionele reinigingswerk
                            <br/><br/>
                            Maak kennis met Steamcleaned, uw betrouwbare partner voor een professionele reiniging van uw textiel. Als een opkomend bedrijf in de branche streven we ernaar om uw textiel een grondige en hoogwaardige reiniging te bieden. Met behulp van onze stoomreiniger en onze sproei-extractiereiniger verwijderen we effectief het vuil uit uw textiel. Vertrouw op Steamcleaned en zij zullen er alles aan doen om de vuiligheid uit u textiel te laten verdwijnen. Bij Steamcleaned geloven we in het leveren van uitmuntendheid en klanttevredenheid. Hoewel we nog een jong bedrijf zijn zonder werknemers, wordt u persoonlijk bediend door onze enthousiaste ondernemer, die met zorg en precisie de vuiligheid uit uw textiel laat verdwijnen. Kies Steamcleaned en laat uw textiel  de behandeling krijgen die het verdient.
                        </p>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}