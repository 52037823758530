import InfoIcon from '@mui/icons-material/Info';
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';
import WeekendIcon from '@mui/icons-material/Weekend';
import CircleIcon from '@mui/icons-material/Circle';

import { Box, Tab, Tabs } from "@mui/material";
import { useNavigate } from 'react-router';

export function BigMenu(props){
    const navigate = useNavigate();

    function handleClick(url){
        navigate(url)
    }

    return (
        <>
            <Box sx={{display: {xs: 'none', md: 'block'}}}>
                <Tabs centered value={props.tabSelected}>
                    {props.menuLinks.map((menuLink)=>(
                        <Tab icon={menuLink.icon} label={menuLink.label} onClick={()=>handleClick(menuLink.url)}/>
                    ))}
                </Tabs>
            </Box>
        </>
    )
}