import { ImageListItem } from "@mui/material";
import { useState } from "react"
import { PhotosDialog } from "./photosFullScreen/PhotosDialog";

export function PhotoItem({pathBefore, pathAfter, alt}){
    const [path, setPath] = useState(pathBefore);
    const [dialogOpen, setDialogOpen] = useState(false);

    function onMouseEnter(){
        setPath(pathAfter);
    }

    function onMouseLeave(){
        setPath(pathBefore);
    }

    function onClick(){
        setDialogOpen(true);
    }

    return (
        <>
            <ImageListItem key={pathBefore}>
                <img
                    src={`${path}`}
                    srcSet={`${path}`}
                    alt={alt}
                    loading="lazy"
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={onClick}
                    />
            </ImageListItem>
            <PhotosDialog open={dialogOpen} handleClose={()=>setDialogOpen(false)} pathBefore={pathBefore} pathAfter={pathAfter}/>
        </>
    )
}