import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export async function authenticate({username, password}){
    if (username === undefined || username === null || username === ""){
        throw Error("Email moet ingevuld zijn")
    }

    if(password === undefined || password === null || password === ""){
        throw Error("Wachtwoord moet ingevuld zijn")
    }

    const auth = getAuth();
    await signInWithEmailAndPassword(auth, username, password)
        .catch((error) => {
            throw Error("Authenticatie mislukt")
        });
}

export function isUserLoggedIn(){
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
        return true;
    } else {
        return false;
    }
}