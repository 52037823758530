import { Card, CardContent, Avatar, Typography } from '@mui/material';

export function WorkMethodCard({index, text}){
    return (
        <>
            <Card style={{backgroundColor: 'rgba(37,170,225, 0.5)'}}>
                <CardContent>
                    <Avatar style={{margin: '10px auto', backgroundColor: 'white', color: 'rgb(37,170,225)'}}>{index}</Avatar>
                    <Typography variant="p" component="p">
                        {text}
                    </Typography>
                </CardContent>
            </Card>
        </>
    )
}