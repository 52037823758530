import { useQuery } from "react-query";
import { getAllPhotos } from "../../services/photos/PhotoService";

export function useGetAllPhotos(category){
    const {
        isLoading,
        isError,
        data
    } = useQuery(['GetAllPhotos', category], () => getAllPhotos(category));

    return {
        isLoading,
        isError,
        data
    }
}