import { CircularProgress, ImageList } from "@mui/material";
import { useGetAllPhotos } from "../../../hooks/photo/useGetAllPhotos";
import { PhotoItem } from "./PhotoItem"

export function PhotosList(){
    const {isLoading, isError, data} = useGetAllPhotos('carpet');

    if(isLoading){
        return (
            <CircularProgress color="primary"/>
        )
    }

    if(isError){
        return (<p>Er liep iets mis tijdens het laden van de foto's, probeer het later opnieuw</p>)
    }

    return (
        <ImageList sx={{ width: "80vw", minHeight: 200, maxHeight: "95vh"}} cols={5}>
            {data.map((image)=>(
                <PhotoItem pathBefore={image.beforePath} pathAfter={image.afterPath} alt={image.alt}/>
            ))}
        </ImageList>
    )
}