import { TopNavigation } from "../components/navigation/TopNavigation";
import { Price } from "../components/seat/price/Price";
import { WorkMethod } from "../components/seat/workMethod/WorkMethod";
import { Photos } from "../components/seat/photos/Photos";

export function Seat(){
    return (
        <>
            <TopNavigation tabSelected={2}/>
            <div style={{marginTop: '-50px'}}></div>
            <Price/>
            <WorkMethod/>
            <Photos/> 
        </>
    )
}