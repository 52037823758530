import { Grid } from "@mui/material"
import { ContactInfoLine } from "./ContactInfoLine"

export function Contact(){
    return (
        <div id="contact">
            <h2 style={{textAlign: "center", marginBottom: "50px", marginTop: "100px", color: "#25aae1", fontSize: "24pt"}}>Contact</h2>
            <div style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                <Grid container spacing={2} style={{width: "80vw"}}>
                    <Grid item xs={12} md={6}>
                        <ContactInfoLine title={"Email:"} value={<a href="mailto:info@steamcleaned.be">info@steamcleaned.be</a>}/>
                        <ContactInfoLine title={"Telefoonnummer:"} value={<a href="tel:+32 470 60 52 37">+32 470 60 52 37</a>}/>
                        <ContactInfoLine title={"Adres:"} value={"Neerveld 7, 2550 Kontich"}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2504.3267759995556!2d4.451397177004209!3d51.12088023886589!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3fa9e766aaaab%3A0x90f05906ba2195db!2sNeerveld%207%2C%202550%20Kontich!5e0!3m2!1snl!2sbe!4v1689196111755!5m2!1snl!2sbe" style={{border:0, width: "100%", height: "400px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="map"></iframe>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}