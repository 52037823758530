// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyBgdPHwttR_37OOwbpK7-c4OcOGeSNL1Dc",
  authDomain: "steamcleaned-70ffa.firebaseapp.com",
  projectId: "steamcleaned-70ffa",
  storageBucket: "steamcleaned-70ffa.appspot.com",
  databaseURL: "https://steamcleaned-70ffa-default-rtdb.europe-west1.firebasedatabase.app/",
  messagingSenderId: "975855234833",
  appId: "1:975855234833:web:1346b2dfb2bf8a019366b0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const database = getDatabase(app);
const auth = getAuth(app);
