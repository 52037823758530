import { useMutation } from "react-query";
import { addPhoto } from "../../services/photos/PhotoService";

export function useAddPhoto({onSuccess, onError}){
    const {
        isLoading,
        mutate
    } = useMutation(addPhoto, {
        onSuccess: onSuccess,
        onError: onError
    })

    return {
        isLoading,
        mutate
    }
}