import { PhotosList } from "./PhotosList";

export function Photos(){
    return (
        <div id="photos">
            <h2 style={{textAlign: "center", marginBottom: "50px", marginTop: "100px", color: "#25aae1", fontSize: "24pt"}}>Resultaten</h2>
            <p style={{textAlign: "center", marginTop: "-30px"}}>Klik op een afbeelding om het resultaat te bekijken</p>
            <div style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                <PhotosList/>
            </div>
        </div>
    )
}