import { ref, getDatabase, get, push, set } from "firebase/database";
import { getDownloadURL, getStorage, ref as refStorage, uploadBytes } from "firebase/storage";

const photos = [
    {
        beforePath: '/images/before/car1.jpg',
        afterPath: '/images/after/car1.jpg',
        category: 'car',
        alt: 'car 1'
    },
    {
        beforePath: '/images/before/car2.jpg',
        afterPath: '/images/after/car2.jpg',
        category: 'car',
        alt: 'car 2'
    },
    {
        beforePath: '/images/before/car3.jpg',
        afterPath: '/images/after/car3.jpg',
        category: 'car',
        alt: 'car 3'
    },
    {
        beforePath: '/images/before/car4.jpg',
        afterPath: '/images/after/car4.jpg',
        category: 'car',
        alt: 'car 4'
    },
    {
        beforePath: '/images/before/car5.jpg',
        afterPath: '/images/after/car5.jpg',
        category: 'car',
        alt: 'car 5'
    },
    {
        beforePath: '/images/before/seat1.jpg',
        afterPath: '/images/after/seat1.jpg',
        category: 'seat',
        alt: 'seat 1'
    },
    {
        beforePath: '/images/before/seat2.jpg',
        afterPath: '/images/after/seat2.jpg',
        category: 'seat',
        alt: 'seat 2'
    },
    {
        beforePath: '/images/before/seat3.jpg',
        afterPath: '/images/after/seat3.jpg',
        category: 'seat',
        alt: 'seat 3'
    },
    {
        beforePath: '/images/before/carpet1.jpg',
        afterPath: '/images/after/carpet1.jpg',
        category: 'carpet',
        alt: 'carpet 1'
    },
    {
        beforePath: '/images/before/carpet2.jpg',
        afterPath: '/images/after/carpet2.jpg',
        category: 'carpet',
        alt: 'carpet 2'
    }
]

export function getAllPhotos(category){
    return photos.filter(p => p.category === category)

    // const dbRef = ref(getDatabase(), 'photos/'+category);
    // return await get(dbRef).then((snapshot) => {
    //     if (snapshot.exists()) {
    //         let data = []

    //         snapshot.forEach((childSnapshot) => {
    //             const childData = childSnapshot.val();
    //             data.push(childData)
    //             })

    //         return data;
    //     } else {
    //       return []
    //     }
    //   }).catch((error) => {
    //     throw Error(error.message)
    //   });
}

export async function addPhoto({alt, imageBefore, imageAfter, category}){
    //validation
    if(alt === undefined ||alt === null || alt === ""){
        throw Error("Beschrijving afbeelding mag niet leeg zijn")
    }

    if(imageBefore === undefined || imageBefore === null){
        throw Error("Afbeelding voor mag niet leeg zijn")
    }

    if(imageAfter === undefined || imageAfter === null){
        throw Error("Afbeelding na mag niet leeg zijn")
    }

    //upload image before
    const storage = getStorage();
    const storageRefBefore = refStorage(storage, 'images/before/'+Date.now()+imageBefore.name);

    await uploadBytes(storageRefBefore, imageBefore)
        .catch((e)=>{
            throw Error("Afbeelding voor uploaden mislukt, probeer later opnieuw")
        });

    const imageBeforeUrl = await getDownloadURL(storageRefBefore);

    //upload image after
    const storageRefAfter = refStorage(storage, 'images/after/'+Date.now()+imageAfter.name);

    await uploadBytes(storageRefAfter, imageAfter)
        .catch((e)=>{
            throw Error("Afbeelding na uploaden mislukt, probeer later opnieuw")
        });

    const imageAfterUrl = await getDownloadURL(storageRefAfter);

    //add to database
    const db = getDatabase();
    const photosRef = ref(db, 'photos/'+category);
    const newPhotosRef = push(photosRef);
    set(newPhotosRef, {
        alt: alt,
        beforePath: imageBeforeUrl,
        afterPath: imageAfterUrl
    })
        .catch((e)=>{
            throw Error("resultaat toevoegen mislukt, probeer later opnieuw")
        });
}