import MenuIcon from '@mui/icons-material/Menu';
import { Box, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function SmallMenu(props){
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    function handleNavigate(url){
        navigate(url)
    }

    return (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' }}}>
                <MenuIcon style={{margin: 5, padding: 5, width: 50, height: 50, color: "white"}} onClick={handleClick}/>
            </Box>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
            >
                {props.menuLinks.map((menuLink) => (
                    <MenuItem onClick={()=>handleNavigate(menuLink.url)} style={{color: "#25aae1"}}>{menuLink.icon}<code>&emsp;</code>{menuLink.label}</MenuItem>
                ))}
            </Menu>
        </>
    )
}