import { Box } from "@mui/material";
import { LoginForm } from "../components/login/LoginForm";

export function Login(){
    return (
        <>
            <Box style={{width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Box style={{padding: 20, backgroundColor: "rgba(37, 170, 225, 0.2)", borderRadius: "20px"}}>
                    <h1 style={{color: "#25aae1", textAlign: "center"}}>Login</h1>
                    <LoginForm/>
                </Box>
            </Box>
        </>
    )
}