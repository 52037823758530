import { Grid } from "@mui/material";

export function ContactInfoLine({title, value}){
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={5} md={4} lg={3}>
                    {title}
                </Grid>
                <Grid item xs={7} md={8} lg={9}>
                    {value}
                </Grid>
            </Grid>
        </>
    )
}