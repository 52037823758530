import { GenPrice } from "../../generalComponents/GenPrice"

const options = [
    {
        displayName: '1 persoonszetel',
        price: 25
    },
    {
        displayName: '2 persoonszetel',
        price: 50
    },
    {
        displayName: '3 persoonszetel',
        price: 75
    },
    {
        displayName: '4 persoonszetel',
        price: 100
    },
    {
        displayName: '5 persoonszetel',
        price: 125
    },
    {
        displayName: '6 persoonszetel',
        price: 150
    }
]

export function Price(){
    return (
        <>
            <GenPrice options={options}/>
        </>
    )
}