import { Grid } from "@mui/material";
import { WorkMethodCard } from "./WorkMethodCard";

export function WorkMethod(){
    return (
    <>
        <h2>Werkwijze</h2>
        <Grid container spacing={2} style={{width: '80vw', margin: '0 auto'}}>
            <Grid item xs={12} sm={6} md={3}>
                <WorkMethodCard index={1} text={"Wanneer we uw zetel reinigen gaan we deze eerst insprayen met textielreiniger."}/>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <WorkMethodCard index={2} text={"Daarna gaan we met onze stoomreiniger de zetel stomen en dan met de textielreiniger de zetel reinigen."}/>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <WorkMethodCard index={3} text={"Als 3de stap, zuigen we al het vuil er uit, met onze sproei-extratiereiniger."}/>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <WorkMethodCard index={4} text={"Als laatste stap laten we de zetel drogen, zodat deze weer helemaal droog is."}/>
            </Grid>
        </Grid>
    </>
    )
}