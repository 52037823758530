import { Box } from "@mui/material";
import { AddResultForm } from "../components/addResult/AddResultForm";
import { useIsLoggedIn } from "../hooks/user/useIsUserLoggedIn";
import { useNavigate } from "react-router-dom";

export function AddResults(){
    const {isLoading, isError, data} = useIsLoggedIn();
    const navigate = useNavigate()

    if(isLoading){
        return (<></>)
    }

    if(isError){
        return (<></>)
    }

    if(!data){
        navigate("/login");
    }

    return (
        <Box style={{width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Box style={{padding: 20, backgroundColor: "rgba(37, 170, 225, 0.2)", borderRadius: "20px"}}>
                <h1 style={{color: "#25aae1"}}>Hier kan een extra resultaat worden toegevoegd</h1>
                <AddResultForm/>
            </Box>
        </Box>
    )
}