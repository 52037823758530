import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";
import LoginIcon from '@mui/icons-material/Login';
import { useAuthenticate } from "./../../hooks/user/useAuthenticate";
import { Delay } from "../delay/Delay";
import { useNavigate } from "react-router-dom";


export function LoginForm(){
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [isError, setIsError] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const {mutate} = useAuthenticate({onSuccess, onError});
    const navigate = useNavigate();

    function handleSubmit(){
        mutate({username, password});
    }

    function onSuccess(){
        navigate("/addresult");
    }

    async function onError(error){
        setIsError(true);
        setErrorMessage(error.message);
        await Delay(5000);
        setIsError(false);
    }

    return (
        <>
            <form>
                <TextField
                    required
                    fullWidth
                    label={"Gebruikersnaam"}
                    value={username}
                    onChange={(e)=>setUsername(e.target.value)}
                    type={"email"}/>
                <TextField
                    required
                    fullWidth
                    label={"Wachtwoord"}
                    value={password}
                    onChange={(e)=>setPassword(e.target.value)}
                    style={{marginTop: 10}}
                    type={"password"}/>
                <Box style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                    <Button variant="contained" style={{marginTop: 20, backgroundColor: "#25aae1", color: "white"}} startIcon={<LoginIcon/>} onClick={handleSubmit}>Login</Button>
                </Box>
                <Box style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                    <span style={{color: "red", display: isError?"block":"none"}}>{errorMessage}</span>
                </Box>
            </form>
        </>
    )
}