import { Price } from "../components/carpet/price/Price";
import { TopNavigation } from "../components/navigation/TopNavigation";
import { Photos } from "../components/carpet/photos/Photos";

export function Carpet(){
    return (
        <>
            <TopNavigation tabSelected={3}/>
            <div style={{marginTop: '-50px'}}></div>
            <Price/>
            <Photos/>
        </>
    )
}