import { useMutation } from "react-query"
import { authenticate } from "../../services/users/UserService"

export function useAuthenticate({onSuccess, onError}){
    const {
        isLoading,
        mutate
    } = useMutation(authenticate, {
        onSuccess: onSuccess,
        onError: onError
    })

    return {
        isLoading,
        mutate
    }
}