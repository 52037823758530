import { TableBody } from './TableBody'

export function GenPrice({options}){
    return (
        <div id="price">
            <h2 style={{textAlign: "center", marginBottom: "50px", marginTop: "100px", color: "#25aae1", fontSize: "24pt"}}>Prijzen</h2>
            <div style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                <table style={{width: "80vw", borderCollapse: "collapse", columnGap: "10px"}}>
                    <thead>
                        <tr>
                            <th></th>
                            <th style={{fontSize: "14pt", backgroundColor: "#25aae1", padding: "5px", borderRadius: "20px 20px 0 0"}}>Prijzen</th>
                        </tr>
                    </thead>
                    <tbody>
                        <TableBody title={"Aantal zitplaatsen"} options={options}/>
                    </tbody>
                </table>
            </div>
        </div>
    )
}