import { useQuery } from "react-query";
import { isUserLoggedIn } from "../../services/users/UserService";

export function useIsLoggedIn(){
    const {
        isLoading,
        isError,
        data
    } = useQuery(['isLoggedIn'], isUserLoggedIn);

    return {
        isLoading,
        isError,
        data
    }
}